export var SearchList = [
  { labe: '物料编号', code: 'IM_MATNR', type: 'input' },
  { labe: '出库基地', code: 'JD_NAME', type: 'input' }
]
export var SearchData = {
  IM_MATNR: '',
  JD_NAME: ''
}
export var tableField = [
  { selectType: 'selection', width: '', type: 'input' },
  { label: '物料编号', code: 'materialCode', type: 'input', width: '' },
  { label: '物料描述', code: 'materialDesc', type: 'input', width: '' },
  { label: '数量', code: 'materialNum', type: 'input', width: '' },
  { label: '工厂', code: 'plant  ', type: 'input', width: '' },
  { label: '库位', code: 'storageLand', type: 'input', width: '' },
  // { label: '查看SN', code: 'sn', type: 'input', width: '' },
  { label: '出库基地', code: 'outboundBase', type: 'input', width: '' }
]
export var tableData = []
