<template>
  <div class="tableList">
    <list-search :childData="childData" @onSearch="search"  @clearSearch="search"></list-search>
    <global-table ref="table" :tableField="tableField"  :tableData="tableData" ></global-table>
  </div>
</template>

<script>
import ListSearch from '@/components/actionList/ListSearch.vue'
import GlobalTable from '@/components/actionList/GlobalTable.vue'
import { SearchList, SearchData, tableField, tableData } from './js/searchlist'
import { request } from '@/assets/js/http.js'
export default {
  name: 'CreatRepairDialog',
  components: { ListSearch, GlobalTable },
  props: ['form'],
  data () {
    return {
      tableField: tableField,
      tableData: tableData,
      searchdata: SearchData,
      childData: { searchList: SearchList, searchData: SearchData }
    }
  },
  mounted () {
    this.getdialogList()
  },
  methods: {
    getdialogList () {
      const obj = {
        IM_LIFNR: this.form.materialCodeName, // 供应商编号
        IM_WERKS: this.form.companyCodeName, // 公司
        IM_MATNR: '',
        JD_NAME: this.form.repairAddress
      }
      request('/api/repair/repair/queryComponent', 'POST', obj).then((res) => {
        if (res.code === '200') {
          this.tableData = res.data
        }
      })
    },
    search: function (data) {
      const obj = {
        IM_LIFNR: this.form.materialCodeName, // 供应商编号
        IM_WERKS: this.form.companyCodeName // 公司
        // JD_NAME: this.form.repairAddress
      }
      this.searchdata = { ...data, ...obj }
      request('/api/repair/repair/queryComponent', 'POST', this.searchdata).then((res) => {
        if (res.code === '200') {
          this.tableData = res.data
        }
      })
    }

  }
}
</script>
<style scoped lang="scss">
.tableList{
padding: 0px;
}
</style>
